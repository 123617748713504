import React from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import SmallHeroSection from "../components/SmallHeroSection";
import DecorativeSection from "../components/DecorativeSection";
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"
import { BsFillTelephoneFill, BsPhone } from "react-icons/bs";


export const query = graphql`
  {
    gallery: allFile(filter: {relativeDirectory: {eq: "gallery"}}) {
        edges {
          node {
            id
            base
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
  }
`

const DeliveryPage = ({ data }) => {
  console.log(data);
  return (
    <Layout page="dostawa">
      <Seo title="Dostawa" />
      <SmallHeroSection />
      <DecorativeSection />

      <section id="price" className="price">
        <div className="section-title">
          <h2><span>Dostawa</span></h2>
          <p className="px-3">
            W trosce o naszych Klientów zapewniamy dowóz pod wskazane adresy. Dbamy o to, aby każdy Państwa dzień rozpoczął się naszym pożywnym śniadaniem, dlatego dowozy odbywają się w <strong>godzinach popołudniowych</strong> dnia poprzedzającego. Dowozy są bezpłatne na terenie całego Tarnowa, miejscowości w obrębie <strong>20km od Tarnowa</strong>, a także do <strong>Zakliczyna, Tuchowa i Brzeska.</strong>
          </p>
        </div>
        {/* <div className="container">

          <p className="text-tel">Masz pytanie odnośnie dostawy ? Skontaktuj się z naszymi kurierami:</p>
          <div className="row row-tel">
            <div className="col-md-6 tel-box tel-box-1"> <div className="nr">
              <a href="tel:+48781062890"><BsFillTelephoneFill />781 062 890
              </a>
            </div></div>
            <div className="col-md-6 tel-box tel-box-2">
              <a href="tel:+48693393979">
                <div className="nr">
                  <BsFillTelephoneFill />693 393 979
                </div>
              </a>
            </div>

          </div>
        </div> */}


        <div className="container map">
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2564.255165699039!2d20.971914316277875!3d50.00657242712828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473d84afb635e68f%3A0xe2ae32bea3874d70!2sBarwy%20Zdrowia%20CATERING%20DIETETYCZNY!5e0!3m2!1spl!2spl!4v1642799082681!5m2!1spl!2spl" width="100%" height="350" frameborder="0" allowfullscreen loading="lazy"></iframe> */}
          {/* <iframe src="https://www.google.com/maps/d/embed?mid=1zkCUITrHs6rtNOao8gfv5TWwUPThQ82y&ehbc=2E312F" width="100%" height="500"></iframe> */}
          <iframe src="https://www.google.com/maps/d/embed?mid=1IxI9qIYMaYoOAldXa2AeTqorsbRmHw2N" width="100%" height="500"></iframe>
          {/* <iframe src="https://www.google.com/maps/d/embed?mid=1IxI9qIYMaYoOAldXa2AeTqorsbRmHw2N&ll=49.981208978763505%2C20.878059950000008&z=11" width="100%" height="500"></iframe> */}
        </div>

        <p className="container text-center py-3">Jeżeli twoja miejscowość nie znalazła się w obrębie darmowej dostawy, zapytaj o możliwość dojazdu.</p>
      </section>

      {/* <section id="map"> */}


      {/* </section> */}

    </Layout>
  )
}

export default DeliveryPage
